import React from 'react'


export default function SuccessPage() {
  return (
    <div>
    <h1>Ödeme Başarılı</h1>
    <p>Ödemeniz başarıyla tamamlandı. Teşekkür ederiz!</p>
  </div>  )
}
