import React from 'react'


export default function Testimonals() {
  return (
          <section className="bg-teal-800">
            <div className="mx-auto max-w-7xl md:grid md:grid-cols-2 md:px-6 lg:px-8">
              <div className="px-6 py-12 md:flex md:flex-col md:border-r md:border-teal-900 md:py-16 md:pl-0 md:pr-10 lg:pr-16">
                <div className="md:flex-shrink-0">
                </div>
                <blockquote className="mt-6 md:flex md:flex-grow md:flex-col">
                  <div className="relative text-lg font-medium text-white md:flex-grow">
                   
                    <p className="relative">
                    Crocodil, dil ve konuşma terapistlerine yönelik muhteşem bir araç! Bu platform sayesinde terapi seanslarını kaydetmek, takip etmek ve analiz etmek artık çok daha kolay. Meslektaşlarımla iş birliği yapmayı kolaylaştırıyor. Crocodil, iş akışımı optimize etmeme ve danışanlarıma daha iyi hizmet sunmama yardımcı oluyor. Bu platform sayesinde, terapi süreçlerini daha verimli ve etkili hale getirebiliyorum. Kesinlikle tüm dil ve konuşma terapistlerine tavsiye ederim!
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex items-start">
                     
                      <div className="">
                        <div className="text-base font-medium text-white">Muhammed Furkan Yaya, Founder</div>
                        <div className="text-base font-medium text-teal-200">Üsküdar Üniversitesi Dil ve Konuşma Terapisi </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
              <div className="border-t-2 border-teal-900 px-6 py-12 md:border-l md:border-t-0 md:py-16 md:pl-10 md:pr-0 lg:pl-16">
                <div className="md:flex-shrink-0">
                <blockquote className="mt-6 md:flex md:flex-grow md:flex-col">
                  <div className="relative text-lg font-medium text-white md:flex-grow">
                   
                    <p className="relative">
                    Crocodil dil ve konuşma terapistleri için harika bir yardımcı! Bu platform iş birliği yapmamızı kolaylaştırıyor ve çalışma sürecimizi daha verimli hale getiriyor. Crocodil sayesinde, terapi seanslarını daha organize bir şekilde planlayabiliyor ve ilerlememizi takip edebiliyoruz. Crocodil, mesleğimizi icra ederken karşılaştığımız zorlukları aşmamıza yardımcı olacak kullanışlı yenilikler de sunuyor. Bu platformu sizlerle paylaşmaktan ve önermekten mutluluk duyuyorum.                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex items-start">
                     
                      <div className="">
                        <div className="text-base font-medium text-white">Ali Aksakal</div>
                        <div className="text-base font-medium text-teal-200">Üsküdar Üniversitesi Dil ve Konuşma Terapisi </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
                </div>
 
              </div>
            </div>
          </section>
        )
      }
      